import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Link } from '@react-pdf/renderer';
import LexdoksLogo from '../../assets/images/logo-blue.png'
import moment from "moment";


const styles = StyleSheet.create({
    main: {
        padding: 50,
        fontSize: 11
    },
    image: {
        width: '100px'
    },
    tableItem: {
        margin: 0,
        padding: 10,
        width: 100,
        border: '1px solid black',
        marginTop: -1,
        marginLeft: -1,
    },
    firstTableItem: {
        width: 20
    },
    secondTableItem: {
        width: 180
    }
});

interface Props {
    name: string;
    plan: string;
    price: number;
    invoiceNr: number;
}

export default function InvoicePdf(props: Props) {
    return (
        <Document>
            <Page size='A4'>
                <View style={styles.main}>

                    <Image src={LexdoksLogo} style={styles.image} />
                    <Text>Legal Data Solutions L.L.C</Text>

                    <Text style={{ marginTop: 20, marginBottom: 5 }}>Faturues:</Text>

                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ lineHeight: 1.2 }}>Legal Data Solutions L.L.C</Text>
                        <Text style={{ width: '150px', textAlign: 'left' }}>Nr. i faturës: {props.invoiceNr}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{ lineHeight: 1.2 }}>Numri Unik Identifikues: 811851436</Text>
                        <Text style={{  width: '150px', textAlign: 'left' }}>Data e lëshimit: {moment().format('DD.MM.YYYY')}</Text>
                    </View>
                    <Text style={{ lineHeight: 1.2 }}>Adresa: Rr. Muharrem Fejza P.N 10000 Prishtinë, Kosovë</Text>
                    <Text style={{ lineHeight: 1.2 }}>Kontakti: info@lexdoks.com</Text>

                    <Text style={{ marginTop: 20, marginBottom: 5 }}>I/E faturuar:</Text>
                    <Text>{props.name}</Text>

                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 40 }}>
                        <Text style={[styles.tableItem, styles.firstTableItem]}>#</Text>
                        <Text style={[styles.tableItem, styles.secondTableItem]}>Shërbimi</Text>
                        <Text style={styles.tableItem}>Njësia</Text>
                        <Text style={styles.tableItem}>Cmimi per njësi</Text>
                        <Text style={styles.tableItem}>Totali</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row'}}>
                        <Text style={[styles.tableItem, styles.firstTableItem]}>1</Text>
                        <Text style={[styles.tableItem, styles.secondTableItem]}>{props.plan}</Text>
                        <Text style={styles.tableItem}>1</Text>
                        <Text style={styles.tableItem}>{props.price}€</Text>
                        <Text style={styles.tableItem}>{props.price}€</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row'}}>
                        <Text style={[styles.tableItem, styles.firstTableItem]} />
                        <Text style={[styles.tableItem, styles.secondTableItem]}>Total</Text>
                        <Text style={styles.tableItem} />
                        <Text style={styles.tableItem}/>
                        <Text style={styles.tableItem}>{props.price}€</Text>
                    </View>

                    <Text style={{ marginTop: 40, marginBottom: 5 }}>Pagesa duhet të bëhet sipas detajeve më poshtë:</Text>
                    <Text style={{ lineHeight: 1.2 }}>Emri i bankës:  ProCredit Bank Sh.a</Text>
                    <Text style={{ lineHeight: 1.2 }}>Numri i llogarisë:  1110342846010135</Text>
                    <Text style={{ lineHeight: 1.2 }}>Pagesa duhet të ketë këtë përshkrim:  {props.invoiceNr}</Text>

                    <Text style={{ marginTop: 40, marginBottom: 5 }}>
                        Sqarim:
                    </Text>
                    <Text style={{ lineHeight: 1.2 }}>
                        Pagesën e kësaj fature mund ta bëni përmes e-banking, në bankë, në Postën e Kosovës apo Capital Ria.
                        Ne aktivizojmë llogarinë tuaj kur pranojmë fondet. Koha në mes të pagesës suaj dhe pranimit të saj varet nga shërbimet bankare apo të institucioneve tjera.
                        Në rast se ju duhet qasje nga momenti kur ju procesoni pagesën ju lutem na dërgoni dëshminë e pagesës në: <Link src='mailto:support@lexdoks.com'>support@lexdoks.com</Link>.
                        Për çdo pyetje apo nevojë për ndihmë na kontaktoni tel: +383 46 622 623
                    </Text>
                </View>
            </Page>
        </Document>
    )
}
